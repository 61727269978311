import styled from 'styled-components';
import { media } from '../css';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${media.phone`flex-direction: column;`}

  a, button {
    margin-right: 20px;

    ${media.phone`
      margin-right: 0;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    `};
  }
`;

export default ButtonContainer;
