import React from 'react';
import styled from 'styled-components';
import LinkButton from '../components/LinkButton';
import ButtonContainer from '../components/ButtonsContainer';
import { media } from '../css';

const PhotoContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 1000px;
  padding: 0 20px;
  margin: 50px auto 0px auto;

  ${media.phone`flex-direction: column;`}
`;

const Image = styled.img`
  width: 270px;
  height: auto;
  border-radius: 100%;

  ${media.tablet`width: 200px;`}

  ${media.phone`margin-right: 0;`}
`;

const Description = styled.p`
  display: flex;
  color: #edeeed;
  font-style: italic;
  margin: 0 0 30px;
  max-width: 630px;

  ${media.phone`margin: 20px 0 20px;`}
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 100px;

  ${media.tablet`margin-left: 30px;`}
  ${media.phone`margin-left: 0px;`}
`;

function Photo({ image, description, buttons }) {
  return (
    <PhotoContainer>
      <Image src={!!image.childImageSharp ? image.childImageSharp.fixed.src : image} alt="Bild på Michaela" />
      <TextContainer>
        <Description>{description}</Description>
        <ButtonContainer>
          {buttons.map((button, index) => <LinkButton key={index} type={button.type} href={button.link}>{button.label}</LinkButton>)}
        </ButtonContainer>
      </TextContainer>
    </PhotoContainer>
  );
}

export default Photo;
