import React from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import { colors, media } from '../css';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  padding: 100px 20px;
  margin: 0px auto;

  ${media.tablet`padding: 30px 20px;`}

  > div,
  > iframe {
    width: calc(50% - 35px);

    ${media.phone`width: 100%;`}
  }
`;

const Header = styled.h2`
  color: ${colors.blue};
`;

const ImageMap = styled.img`
  max-width: 100%;
`;

function Location({ title, description }) {
  return (
    <Fade bottom duration={500} distance="30px">
      <Container>
        <div>
          <Header>{title}</Header>
          <p>{description}</p>
        </div>
        <div>
          <a title="Länk till karta" href="https://www.google.com/maps/place/Salong+V%C3%A4sterg%C3%A5rden/@58.4378104,12.7961848,11z/data=!4m5!3m4!1s0x465ad5c497a36c31:0x4b813d43b59e78!8m2!3d58.4378076!4d12.9362605?hl=en">
            <ImageMap alt="Karta" src="https://maps.googleapis.com/maps/api/staticmap?center=58.421031,13.2411968&zoom=9&size=465x400&maptype=roadmap&markers=color:red%7C58.437661,12.935882&key=AIzaSyAlW_SnQtL2ixYAeH8oL94wXiKq9U48a34"/>
          </a>
        </div>
      </Container>
    </Fade>
  );
}

export default Location;
