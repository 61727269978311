import React from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import { colors, media } from '../css';

const Container = styled.div`
  background-color: #fff;
  padding: 40px 70px;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  ${media.tablet`padding: 20px;`}
`;

const Header = styled.h2`
  color: ${colors.gold};
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  display: flex;
  min-height: 60px;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(69, 67, 67, 0.3);
  }

  ${media.phone`flex-direction: column;`}
`;

const ListItemTag = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 40px;
  margin-right: 60px;
  border-radius: 30px;
  color: ${colors.light};
  background-color: ${colors.grey};

  ${media.tablet`margin-right: 30px;`}
  ${media.phone`
    width: 100%;
    height: 50px;
    margin-right: 0;
    padding: 0 20px;
    justify-content: space-between;
    border-radius: 0;
  `}
`;
const ListItemText = styled.div`
  flex: 1 0 50%;
  flex-flow: row wrap;

  ${media.phone`
    width: 100%;
    margin: 20px 0;
  `}
`;

const ListItemPrice = styled.div`
  flex: 0 0 auto;
  ${media.phone`display: none;`}
`;

const ListItemPricePhone = styled.span`
  display: none;
  margin-left: 10px;
  ${media.phone`display: inline-block;`}
`;

function Prislista({ title, description, items }) {
  const rows = items || [];
  return (
    <Container>
      <Fade bottom duration={500} distance="30px">
        <Header>{title}</Header>
        <p>{description}</p>
        <List>
          {rows.map(({ tag, text, price }, index) => (
            <ListItem key={index}>
              <ListItemTag>
                <span>{tag}</span>
                <ListItemPricePhone>{price}</ListItemPricePhone>
              </ListItemTag>
              <ListItemText>{text}</ListItemText>
              <ListItemPrice>{price}</ListItemPrice>
            </ListItem>
          ))}
        </List>
      </Fade>
    </Container>
  );
}

export default Prislista;
