import PropTypes from 'prop-types';
import React from 'react';
import styled  from 'styled-components';
import Logo from '../components/Logo';
import Navbar from '../components/Navbar';
import Photo from '../components/Photo';
import { colors, media } from '../css';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const Container = styled.div`
  position: relative;
  padding: 30px 0 100px;

  ${media.tablet`padding-bottom: 30px;`}
`;

function PageHeader({ background, photo, description, buttons }) {
  return (
    <Container>
      <PreviewCompatibleImage
        alt="Grå bakgrund"
        image={background}
        backgroundColor={colors.grey}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: -1
        }}
      />
      <Logo useHeader={true} />
      <Navbar />
      <Photo image={photo} description={description} buttons={buttons}/>
    </Container>
  );
}

PageHeader.propTypes = {
  background: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  photo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  description: PropTypes.string,
  buttons: PropTypes.array,
};

export default PageHeader;
