import { graphql } from 'gatsby';
import styled from 'styled-components';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import { media, colors } from '../css';
import PropTypes from 'prop-types';
import React from 'react';
import Article from '../components/Article';
import StartpageLayout from '../components/StartpageLayout';
import PageHeader from '../components/PageHeader';
import Prislista from '../components/Prislista';
import Location from '../components/Location';
import StartPage from '../templates/start-page';
import Footer from '../components/Footer';

const ArticleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  padding: 0 20px;
  margin: 100px auto 90px;

  ${media.tablet`margin: 30px 20px 20px;`}
  ${media.phone`margin: 30px 0px;`}

  > div {
    width: calc(50% - 35px);

    ${media.phone`width: 100%;`}
  }
`;

const PrislistorContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 100px;

  ${media.tablet`padding: 30px 20px;`}
`;

const LocationContainer = styled.div`
  background-color: ${colors.light};
`;

export default function IndexPage({
  data: {
    markdownRemark: {
      frontmatter: { metadata, top, articles, prislistorBackground, prislistor, map },
    },
  },
}) {
  return (
    <StartpageLayout {...metadata}>
      <PageHeader {...top} />
      <ArticleContainer id="events">
        {articles.map((article, index) => (
          <Article key={index} {...article} />
        ))}
      </ArticleContainer>
      <PrislistorContainer id="prislista">
        <PreviewCompatibleImage
          alt="Grå bakgrund"
          image={prislistorBackground}
          backgroundColor={colors.gold}
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            width: '175%',
            height: '100%',
            zIndex: -1,
          }}
        />
        {prislistor.map((prislista, index) => (
          <Prislista key={index} {...prislista} />
        ))}
      </PrislistorContainer>
      <LocationContainer id="hitta-hit">
        <Location {...map} />
      </LocationContainer>
      <Footer background={top.background} />
    </StartpageLayout>
  );
}

StartPage.propTypes = {
  metadata: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  top: PropTypes.shape({
    background: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    photo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    description: PropTypes.string,
    buttons: PropTypes.array,
  }),
  articles: PropTypes.arrayOf({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string,
  }),
  prislistorBackground: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  prislistor: PropTypes.arrayOf({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf({
      tag: PropTypes.objectOf({ text: PropTypes.string, color: PropTypes.string }),
      text: PropTypes.string,
      price: PropTypes.string,
    }),
  }),
  map: PropTypes.arrayOf({
    title: PropTypes.string,
    description: PropTypes.string,
    lat: PropTypes.string,
    lng: PropTypes.string,
    zoom: PropTypes.string,
  }),
};

export const pageQuery = graphql`
  query StartpageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "start-page" } }) {
      frontmatter {
        metadata {
          title
          description
        }
        top {
          background {
            childImageSharp {
              fluid(maxWidth: 2048) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          photo {
            childImageSharp {
              fixed(width: 270, height: 270) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          description
          buttons {
            type
            label
            link
          }
        }
        articles {
          image {
            childImageSharp {
              fluid(maxWidth: 465, maxHeight: 320, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          text
          link
        }
        prislistorBackground {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        prislistor {
          title
          description
          items {
            tag
            text
            price
          }
        }
        map {
          title
          description
        }
      }
    }
  }
`;
