import React from 'react';
import Helmet from 'react-helmet';
import MobileNav from './MobileNav';
import './all.scss';

const TemplateWrapper = ({ title, description, children }) => (
  <div>
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="google-site-verification" content="tzyyHALStCS_REOz97iNXP_KOlZfnwe9Hw3QJkefvUY" />

      <link rel="icon" type="image/png" href="/img/favicon-32x32.png" sizes="32x32" />
      <link rel="icon" type="image/png" href="/img/favicon-16x16.png" sizes="16x16" />
      <meta name="theme-color" content="#fff" />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content="/" />
    </Helmet>
    <MobileNav />
    <div>{children}</div>
  </div>
);

export default TemplateWrapper;
