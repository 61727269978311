import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import PropTypes from 'prop-types';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import { colors, media } from '../css';
import LinkButton from './LinkButton';

const ArticleButton = styled(LinkButton)`
  margin: 20px 0;
`;

const Header = styled.h2`
  color: ${colors.blue};
  margin: 20px 0 30px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  ${media.phone`align-items: stretch;`}
`;

function Article({ image, title, text, link }) {
  return (
    <Fade bottom duration={500} distance="30px">
      <Container>
        <PreviewCompatibleImage alt={title} image={image} style={{height: '320px', width: '100%'}} />
        <Header>{title}</Header>
        <LinesEllipsis style={{width: '100%', flex: '1 1 auto'}} text={text} maxLine="5" ellipsis="..." trimRight basedOn="letters" />
        <ArticleButton type="ghost" href={link}>
          Läs mer
        </ArticleButton>
      </Container>
    </Fade>
  );
}

Article.propTypes = {
  articles: PropTypes.arrayOf({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string,
  }),
};

export default Article;
