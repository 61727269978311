import styled, { css } from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const LinkButton = styled(OutboundLink)`
  display: inline-block;
  height: 60px;
  padding: 0px 40px;
  border-radius: 30px;
  font-family: 'Playfair Display';
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 60px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid transparent;

  ${props => props.type === 'primary' && css`
    background-color: #565e71;
    color: #fff;
  `}

  ${props => props.type === 'ghost' && css`
    color: #e2a204;
    border-color: #e2a204;
    background: transparent;
  `}
`;

export default LinkButton;